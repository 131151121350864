import Vue from 'vue';
import AxiosPlugin from './axios/axiosPlugin';

const scheme = process.env.VUE_APP_API_HTTPS === 'true' ? 'https' : 'http';

Vue.use(AxiosPlugin, {
  baseURL: `${scheme}://${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}${process.env.VUE_APP_API_ROUTE}`,
  timeout: 20000,
  headers: {
    common: {
      'Content-Type': 'application/ld+json',
    },
    get: {},
    post: {},
    put: {},
  },
});
