


























import {
  Component, Vue,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';

@Component({
  components: { ValidationProvider },
})
export default class MediaObjectEditForm extends Vue implements CreateFormInterface {
  private file: File | null = null;

  get filename() {
    if (this.file instanceof File) {
      return this.file.name;
    }

    return '';
  }

  get filesize() {
    if (this.file instanceof File) {
      if (this.file.size > 999999) {
        return `${(this.file.size / 1000000).toFixed(2)} MB`;
      }

      return `${Math.ceil(this.file.size / 1000)} KB`;
    }

    return '';
  }

  prepareLocalResource() {
    if (this.file !== null) {
      const form = new FormData();
      form.append('file', this.file);

      return form;
    }

    return null;
  }

  resetLocalResource() {
    this.file = null;
  }
}
