









































import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import Quill from 'quill';
import { ValidationProvider } from 'vee-validate';
import { TextContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { toolbarOptions } from '@/utils/quill';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
import { TemplateSegment } from '@/types/resources/Template';

@Component({
  components: { ContentBlockFormWrapper, ValidationProvider },
})
export default class TextContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @Prop({ type: Object, default: undefined }) segment!: Partial<TemplateSegment> | undefined;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<TextContentBlockInterface>;

  // eslint-disable-next-line
  private quill: any;

  get textType(): 'div' | 'textarea' | 'input' | 'number' {
    if (this.syncedContentBlock.config) {
      if (this.syncedContentBlock.config.wysiwyg === true) {
        return 'div';
      }
      if (this.syncedContentBlock.config.multiline === true) {
        return 'textarea';
      }
      if (this.syncedContentBlock.config.number === true) {
        return 'number';
      }
    }

    return 'input';
  }

  get min(): number | undefined {
    return this.syncedContentBlock.config.min;
  }

  get max(): number | undefined {
    return this.syncedContentBlock.config.max;
  }

  get step(): number | undefined {
    return this.syncedContentBlock.config.step;
  }

  initializeQuill() {
    if (this.textType === 'div') {
      this.quill = new Quill(this.$refs[`editor_${this.syncedContentBlock.name}_${this.locale}`], {
        modules: {
          toolbar: toolbarOptions,
          history: true,
        },
        theme: 'snow',
      });

      if (this.syncedContentBlock.text) {
        const delta = this.quill.clipboard.convert(
          this.syncedContentBlock.translations[this.locale].text,
        );
        this.quill.setContents(delta, 'silent');
      }

      this.quill.on('text-change', () => {
        this.syncedContentBlock.translations[this.locale].text = this.quill.root.innerHTML;
      });
    }
  }

  mounted() {
    this.$nextTick(async () => {
      if (
        !this.syncedContentBlock.config
        && this.syncedContentBlock.templateSegment
        && typeof this.syncedContentBlock.templateSegment === 'object'
      ) {
        const response = await this.$api.get(this.syncedContentBlock.templateSegment['@id']);
        if (response.status === 200) {
          this.syncedContentBlock.config = response.data.config;
        }
      }

      this.initializeQuill();
    });
  }
}
