/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { Template, TemplateSegment } from '@/types/resources/Template';
import { contentBlockTypes } from '@/utils/ContentBlockTypes';
import { Translatable } from '@/types/resources/Translatable';
import {
  CollectionContentBlockInterface,
  ContentBlockInterface,
} from '@/types/resources/ContentBlockInterface';
import { ContentBlockDefinition } from '@/types/interfaces/Template/ContentBlockConfiguration';
import { Content } from '@/types/resources/Content';
import { mixins } from 'vue-class-component';
import FileContentBlockMixin from '@/mixins/ContentBlocks/FileContentBlockMixin';
import ImageContentBlockMixin from '@/mixins/ContentBlocks/ImageContentBlockMixin';
import ReferenceContentBlockMixin from '@/mixins/ContentBlocks/ReferenceContentBlockMixin';
import TextContentBlockMixin from '@/mixins/ContentBlocks/TextContentBlockMixin';
import CollectionContentBlockMixin from '@/mixins/ContentBlocks/CollectionContentBlockMixin';
import { TemplateSegmentConfiguration } from '@/types/resources/TemplateConfiguration';

@Component
export default class CreateContentBlockMixin extends mixins(
  FileContentBlockMixin,
  ImageContentBlockMixin,
  ReferenceContentBlockMixin,
  TextContentBlockMixin,
  CollectionContentBlockMixin,
) {
  protected content!: Partial<Content>;

  getContentBlockComponentFromType(type: string): string {
    return `${type.slice(0, 1).toUpperCase()}${type.slice(1)}ContentBlockForm`;
  }

  initializeContentBlock(
    contentBlockDefinition: ContentBlockDefinition,
    block: Partial<ContentBlockInterface>,
    name: string,
  ): Partial<ContentBlockInterface> {
    // If ContentBlockInterface is translatable, add the contentBlock's data
    // to translations[locale] key.
    if (contentBlockDefinition.translatable) {
      this.$root.$i18n.availableLocales.forEach((locale) => {
        if (block.translations) {
          block.translations[locale] = {
            ...contentBlockDefinition.defaultBlock,
            name,
            locale,
          };
        }
      });
    } else {
      // If it's not translatable, just store the info in the top level
      block = {
        ...block, // copy prepared block from above
        ...contentBlockDefinition.defaultBlock,
      };
    }

    if (block.type === 'collection') {
      this.initializeCollectionContentBlock(block, name);
    }

    return block;
  }

  initializeCollectionContentBlock(block: Partial<CollectionContentBlockInterface>, name: string) {
    block.contentBlocks = [];
    block.translations = {};

    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (!block.translations[locale]) {
        block.translations[locale] = {};
      }
      block.translations[locale].name = name;
      block.translations[locale].locale = locale;
    });
  }

  initializeContentBlockFromSegment(s: Partial<TemplateSegment>) {
    if (this.content?.contentBlocks !== undefined) {
      // Get contentBlockDefinition for default values and to check if it's translatable
      const contentBlockDefinition = contentBlockTypes.find((b) => b.name === s.contentBlockType);

      if (contentBlockDefinition) {
        let configuration;
        if (s.configurations?.length) {
          configuration = s.configurations.map((c: TemplateSegmentConfiguration) => ({
            name: c.name,
          }));
        }
        // Prepare the content block
        let block: Partial<Translatable<ContentBlockInterface>> = {
          type: s.contentBlockType,
          configuration,
          name: s.name,
          config: s.config,
          templateSegment: s['@id'],
          translations: {},
        };

        block = this.initializeContentBlock(contentBlockDefinition, block, s.name as string);

        // Add the contentBlock to the content
        this.content.contentBlocks.push(block);
      }
    }
  }

  initializeContentBlocksFromTemplate(value: Template) {
    if (value) {
      // Reset contentBlocks
      this.$set(this.content, 'contentBlocks', []);

      // Loop over segments from Template
      value.segments.forEach((s) => {
        this.initializeContentBlockFromSegment(s);
      });
    }
  }
}
