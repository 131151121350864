























































import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { ASYNC_SEARCH, Treeselect } from '@riophae/vue-treeselect';
import { TextContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { asyncForEach } from '@/utils/asyncForEach';
import { ImageObject } from '@/types/resources/ImageObject';
import { Translatable } from '@/types/resources/Translatable';
import { TemplateSegment } from '@/types/resources/Template';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
import ContentBlockFormWrapper from './ContentBlockFormWrapper.vue';

@Component({
  components: { ContentBlockFormWrapper, Treeselect },
})
export default class ImageContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Object, default: undefined }) segment!: Partial<TemplateSegment> | undefined;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<TextContentBlockInterface>;

  private uploading = false;

  private file: File | null = null;

  private error = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadImageObjects({ action, searchQuery, callback }: any) {
    if (action === ASYNC_SEARCH) {
      const filenameSearch = await this.$api.get('/api/image_objects', {
        params: { filename: searchQuery },
      });
      const captionSearch = await this.$api.get('/api/image_objects', {
        params: { caption: searchQuery },
      });
      const options: { id: string; label: string }[] = [];

      if (filenameSearch.status === 200 && filenameSearch.data['hydra:member']) {
        filenameSearch.data['hydra:member'].forEach((el: ImageObject) => {
          if (!options.find((o) => o.id === el['@id'])) {
            options.push({
              id: el['@id'],
              label: `${el.filename} (${el.caption})`,
            });
          }
        });
      }

      if (captionSearch.status === 200 && captionSearch.data['hydra:member']) {
        captionSearch.data['hydra:member'].forEach((el: ImageObject) => {
          if (!options.find((o) => o.id === el['@id'])) {
            options.push({
              id: el['@id'],
              label: `${el.filename} (${el.caption})`,
            });
          }
        });
      }

      callback(null, options);
    }
  }

  async uploadImage() {
    this.uploading = true;
    if (this.file) {
      if (this.file) {
        const form = new FormData();
        form.append('file', this.file);

        try {
          const response = await this.$api.post('/api/image_objects', form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 201 && response.data) {
            this.syncedContentBlock.translations[this.locale].image = response.data['@id'];

            this.$nextTick(() => {
              this.$forceUpdate();
              this.$nextTick(() => {
                // This line loads the name in Treeselect's cache so it has a label
                // eslint-disable-next-line
                (this.$refs[`${this.syncedContentBlock.name.replace(' ', '-')}_${this.syncedContentBlock.position}_${this.locale}`] as any)
                  .forest.nodeMap[this.syncedContentBlock.translations[this.locale].image]
                  .label = `${response.data.filename}`;
                this.file = null;
              });
            });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          if (e.response?.data?.['hydra:description']) {
            this.error = e.response.data['hydra:description'];
          }
        }
      }
    }
    this.uploading = false;
  }

  /**
   * Load Filenames and Caption of all Images for all Translations
   */
  async mounted() {
    /* eslint-disable */
    await asyncForEach(Object.keys(this.syncedContentBlock.translations), async (locale: string) => {
      if (locale === this.locale) {
        if (typeof this.syncedContentBlock.translations[locale].image === 'string') {
          const response = await this.$api.get(this.syncedContentBlock.translations[locale].image);
          if (response.status === 200) {
            (this.$refs[this.syncedContentBlock.name.replace(' ', '-') + '_' + this.syncedContentBlock.position + '_' + this.locale] as any)
              .forest.nodeMap[this.syncedContentBlock.translations[locale].image].label
              = `${response.data.filename} (${response.data.caption})`;
          }
        }
      }
    });
    /* eslint-enable */
  }
}
