/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { TextContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { mixins } from 'vue-class-component';
import TemplateSegmentConfigurationMixin
  from '@/mixins/ContentBlocks/shared/TemplateSegmentConfigurationMixin';
import { TemplateSegmentConfiguration } from '@/types/resources/TemplateConfiguration';

@Component
export default class TextContentBlockMixin extends mixins(TemplateSegmentConfigurationMixin) {
  async loadTextContentBlock(block: Translatable<TextContentBlockInterface>) {
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      const response = await this.$api.get(block.templateSegment['@id']);
      if (response.status === 200) {
        block.templateSegment = response.data['@id'];
        block.config = response.data.config;
      }

      block.configuration = response.data.configurations.map((c: TemplateSegmentConfiguration) => {
        const existingConfiguration: null | TemplateSegmentConfiguration = block.configuration
          ?.find((e: Partial<TemplateSegmentConfiguration>) => e.name === c.name);

        if (existingConfiguration) {
          return {
            name: c.name,
            options: c.multiple ? existingConfiguration.options : existingConfiguration.options[0],
          };
        }
        return { name: c.name };
      });

      this.$root.$i18n.availableLocales.forEach((locale) => {
        if (block.translations[locale] === undefined) {
          block.translations[locale] = {
            id: undefined,
            text: '',
            name: block.name,
            locale,
          };
        }
      });
    }
  }

  prepareTextContentBlock(block: Translatable<TextContentBlockInterface>):
    Partial<Translatable<TextContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<TextContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      type: 'text',
    };

    preparedBlock.configuration = this.prepareContentBlockConfiguration(block);

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        if (block.translations[locale].text) {
          preparedBlock.translations[locale] = {
            id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
            templateSegment,
            text: block.translations[locale].text,
            name: block.translations[locale].name,
            locale,
          };
        }
      }
    });

    // eslint-disable-next-line
    // @ts-ignore
    if (Object.keys(preparedBlock.translations).length === 0) {
      return undefined;
    }

    return preparedBlock;
  }
}
