import { Vue, Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { Content } from '@/types/resources/Content';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { asyncForEach } from '@/utils/asyncForEach';
import { TemplateConfiguration } from '@/types/resources/TemplateConfiguration';
import slugify from 'slugify';

@Component
export default class ContentMixin extends Vue {
  protected content: Partial<Translatable<Content>>
    & { contentBlocks?: Partial<ContentBlockInterface>[] } = {
      translations: {},
      contentBlocks: [],
    };

  /**
   * When preparing the Content for PUTting, we need to
   * replace all object with their @ids. We also need to
   * remove all properties that don't need putting. See
   * ContentBlockTypes.ts what is needed.
   */
  async prepareLocalResource(): Promise<Partial<Translatable<Content>>> {
    const content: Partial<Translatable<Content>> = {
      contentBlocks: [],
      template: typeof this.content.template === 'object' ? this.content.template['@id'] : this.content.template,
      translations: {},
    };

    if (this.content.configuration) {
      content.configuration = this.content.configuration
        .filter((c: TemplateConfiguration) => c != null)
        .map((c: Partial<TemplateConfiguration>) => {
          let { options } = c;
          if (c.options === undefined) {
            options = [];
          } else if (!Array.isArray(c.options)) {
            options = [c.options];
          }

          return { name: c.name, options };
        });
    }

    if (this.content.translations) {
      Object.keys(this.content.translations).forEach((locale) => {
        if (this.content.translations) {
          // Only add translations for locales that are filled out
          if (this.content.translations[locale].name) {
            if (content.translations != null) {
              content.translations[locale] = {
                id: this.$route.query.duplicate ? undefined : this.content.translations[locale]['@id'],
                locale,
                name: this.content.translations[locale].name,
                slug: slugify(this.content.translations[locale].name as string),
                status: this.content.translations[locale].status,
              };
            }
          }
        }
      });
    }

    if (this.content.contentBlocks?.length) {
      await asyncForEach(this.content.contentBlocks, async (block, index) => {
        let prepareFunctionName = `prepare${block.type?.slice(0, 1).toUpperCase()}${block.type?.slice(1)}ContentBlock`;
        if (block['@type']) {
          prepareFunctionName = `prepare${block['@type']}`;
        }
        // I don't know how to fix the Index type of this Vue Component, so I @ts-ignore
        // TypeScripts warning. Then I need eslint-disable-next-next to remove ESLint's warning.
        // eslint-disable-next-line
        // @ts-ignore
        const preparedBlock = await this[prepareFunctionName](block);
        if (preparedBlock) {
          content.contentBlocks.push({
            ...preparedBlock,
            position: index,
          });
        }
      });
    }

    return content;
  }
}
