/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { FileContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import TemplateSegmentConfigurationMixin
  from '@/mixins/ContentBlocks/shared/TemplateSegmentConfigurationMixin';
import { mixins } from 'vue-class-component';

@Component
export default class FileContentBlockMixin extends mixins(TemplateSegmentConfigurationMixin) {
  loadFileContentBlock(block: Translatable<FileContentBlockInterface>) {
    Object.keys(block.translations).forEach((locale: string) => {
      const { file } = block.translations[locale];
      if (file && typeof file === 'object') {
        block.translations[locale].file = file['@id'];
      }
    });

    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (block.translations[locale] === undefined) {
        block.translations[locale] = {
          id: undefined,
          file: undefined,
          name: block.name,
          locale,
        };
      }
    });
  }

  prepareFileContentBlock(block: Translatable<FileContentBlockInterface>):
    Partial<Translatable<FileContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<FileContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      type: 'file',
    };

    preparedBlock.configuration = this.prepareContentBlockConfiguration(block);

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        if (block.translations[locale].file) {
          preparedBlock.translations[locale] = {
            id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
            templateSegment,
            file: block.translations[locale].file,
            name: block.translations[locale].name,
            locale,
          };
        }
      }
    });

    // eslint-disable-next-line
    // @ts-ignore
    if (Object.keys(preparedBlock.translations).length === 0) {
      return undefined;
    }

    return preparedBlock;
  }
}
