/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { CollectionContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { mixins } from 'vue-class-component';
import FileContentBlockMixin from '@/mixins/ContentBlocks/FileContentBlockMixin';
import ImageContentBlockMixin from '@/mixins/ContentBlocks/ImageContentBlockMixin';
import TextContentBlockMixin from '@/mixins/ContentBlocks/TextContentBlockMixin';
import ReferenceContentBlockMixin from '@/mixins/ContentBlocks/ReferenceContentBlockMixin';
import { asyncForEach } from '@/utils/asyncForEach';
import { Translatable } from '@/types/resources/Translatable';
import TemplateSegmentConfigurationMixin
  from '@/mixins/ContentBlocks/shared/TemplateSegmentConfigurationMixin';

@Component
export default class CollectionContentBlockMixin extends mixins(
  FileContentBlockMixin,
  ImageContentBlockMixin,
  TextContentBlockMixin,
  ReferenceContentBlockMixin,
  TemplateSegmentConfigurationMixin,
) {
  // eslint-disable-next-line
  async loadCollectionContentBlock(block: CollectionContentBlockInterface) {
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      const response = await this.$api.get(block.templateSegment['@id']);
      if (response.status === 200) {
        block.templateSegment = response.data['@id'];
        block.config = response.data.config;
      }
    }

    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (block.translations[locale] === undefined) {
        block.translations[locale] = {
          id: undefined,
          name: block.name,
          locale,
        };
      }
    });

    await asyncForEach(block.contentBlocks, async (b) => {
      const loadFunctionName = `load${b.type?.slice(0, 1).toUpperCase()}${b.type?.slice(1)}ContentBlock`;

      if (block.config?.config?.[b.type]) {
        b.config = block.config?.config?.[b.type];
      }

      // eslint-disable-next-line
      // @ts-ignore
      await this[loadFunctionName](b);
    });
  }

  async prepareCollectionContentBlock(block: CollectionContentBlockInterface):
    Promise<Partial<Translatable<CollectionContentBlockInterface>> | undefined> {
    let templateSegmentIri: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegmentIri = block.templateSegment['@id'];
    } else {
      templateSegmentIri = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<CollectionContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment: templateSegmentIri,
      contentBlocks: [],
      translations: {},
      type: 'collection',
    };

    preparedBlock.configuration = this.prepareContentBlockConfiguration(block);

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        preparedBlock.translations[locale] = {
          id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
          templateSegment: templateSegmentIri,
          name: block.translations[locale].name,
          locale,
        };
      }
    });

    if (block.contentBlocks?.length) {
      block.contentBlocks.forEach((b, index) => {
        let prepareFunctionName = `prepare${b.type?.slice(0, 1).toUpperCase()}${b.type?.slice(1)}ContentBlock`;
        if (b['@type']) {
          prepareFunctionName = `prepare${b['@type']}`;
        }
        // I don't know how to fix the Index type of this Vue Component, so I @ts-ignore
        // TypeScripts warning. Then I need eslint-disable-next-next to remove ESLint's warning.
        preparedBlock.contentBlocks.push({
          // eslint-disable-next-line
          // @ts-ignore
          ...this[prepareFunctionName](b),
          position: index,
          templateSegment: undefined,
        });
      });
    }

    const segmentResponse = await this.$api.get(templateSegmentIri);

    if (segmentResponse.status === 200 && segmentResponse.data
      && !segmentResponse.data.required && block.contentBlocks.length === 0) {
      return undefined;
    }

    return preparedBlock;
  }
}
