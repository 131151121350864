/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { ReferenceContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { mixins } from 'vue-class-component';
import TemplateSegmentConfigurationMixin
  from '@/mixins/ContentBlocks/shared/TemplateSegmentConfigurationMixin';

@Component
export default class ReferenceContentBlockMixin extends mixins(TemplateSegmentConfigurationMixin) {
  async loadReferenceContentBlock(block: Translatable<ReferenceContentBlockInterface>) {
    // noinspection DuplicatedCode
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      const response = await this.$api.get(block.templateSegment['@id']);
      if (response.status === 200) {
        block.templateSegment = response.data['@id'];
        block.config = response.data.config;
      }
    }

    if (block.reference && typeof block.reference === 'object') {
      block.reference = block.reference['@id'];
    }

    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (block.translations[locale] === undefined) {
        block.translations[locale] = {
          id: undefined,
          reference: undefined,
          name: block.name,
          locale,
        };
      }
    });
  }

  prepareReferenceContentBlock(block: Translatable<ReferenceContentBlockInterface>):
    Partial<Translatable<ReferenceContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<ReferenceContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      type: 'reference',
      reference: block.reference,
    };

    preparedBlock.configuration = this.prepareContentBlockConfiguration(block);

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        if (block.reference) {
          preparedBlock.translations[locale] = {
            id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
            templateSegment,
            name: block.translations[locale].name,
            locale,
          };
        }
      }
    });

    if (!preparedBlock.reference) {
      return undefined;
    }

    return preparedBlock;
  }
}
