






















































































import {
  Vue, Component, PropSync, Watch,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { TemplateSegment } from '@/types/resources/Template';
import {
  contentBlockTypes,
  exampleCollectionConfig,
  exampleTextConfig,
  exampleReferenceConfig,
} from '@/utils/ContentBlockTypes';

@Component({
  components: { ValidationProvider },
})
export default class TemplateSegmentForm extends Vue {
  @PropSync('segment', { type: Object, required: true }) syncedSegment!: TemplateSegment;

  private segmentConfigurationValid: boolean | null = null;

  private configValid: boolean | null = null;

  get iri() {
    return this.syncedSegment['@id'].split('/').reverse()[0];
  }

  get contentBlockTypes() {
    return contentBlockTypes.map((value) => ({
      value: value.name,
      text: this.$t(`resource.${value.label}.label`),
    }));
  }

  get segmentConfiguration(): string {
    return JSON.stringify(this.syncedSegment.configurations, undefined, 4);
  }

  set segmentConfiguration(value: string) {
    try {
      this.syncedSegment.configurations = JSON.parse(value);
      this.segmentConfigurationValid = true;
    } catch (e) {
      this.segmentConfigurationValid = false;
    }
  }

  get contentBlockTypeHasConfig(): boolean {
    const block = contentBlockTypes.find(
      (b) => b.name === this.syncedSegment.contentBlockType,
    );

    return !!(block && block.config);
  }

  get contentBlockConfig(): string {
    return JSON.stringify(this.syncedSegment.config, undefined, 4);
  }

  set contentBlockConfig(value: string) {
    try {
      this.syncedSegment.config = JSON.parse(value);
      this.configValid = true;
    } catch (e) {
      this.configValid = false;
    }
  }

  removeThisSegment() {
    this.$emit('remove-clicked', this.syncedSegment['@id']);
  }

  @Watch('syncedSegment.contentBlockType')
  loadExampleConfig(value: boolean) {
    if (value) {
      if (this.syncedSegment.contentBlockType === 'text') {
        this.syncedSegment.config = exampleTextConfig;
      }
      if (this.syncedSegment.contentBlockType === 'collection') {
        this.syncedSegment.config = exampleCollectionConfig;
      }
      if (this.syncedSegment.contentBlockType === 'reference') {
        this.syncedSegment.config = exampleReferenceConfig;
      }
    }
  }
}
