import { ContentBlockDefinition } from '@/types/interfaces/Template/ContentBlockConfiguration';

export const contentBlockTypes: ContentBlockDefinition[] = [
  {
    name: 'text',
    configuration: [],
    label: 'TextContentBlock',
    config: {
      multiline: 'boolean',
      wysiwyg: 'boolean',
    },
    defaultBlock: {
      text: '',
    },
    translatable: true,
  },
  {
    name: 'image',
    configuration: [],
    label: 'ImageContentBlock',
    config: null,
    translatable: true,
    defaultBlock: {
      image: null,
    },
  },
  {
    name: 'file',
    configuration: [],
    label: 'FileContentBlock',
    config: null,
    translatable: true,
    defaultBlock: {
      file: null,
    },
  },
  {
    name: 'reference',
    configuration: [],
    label: 'ReferenceContentBlock',
    translatable: true,
    config: {
      mode: {
        type: 'string',
        choices: ['modal', 'search'],
      },
      allowedTemplates: {
        type: 'array',
      },
    },
    defaultBlock: {
      reference: null,
    },
  },
  {
    name: 'collection',
    configuration: [],
    label: 'ContentBlockCollection',
    translatable: false,
    defaultBlock: {
      contentBlocks: [],
    },
    config: {
      allowedTypes: {
        type: 'array',
        choices: ['text', 'image', 'file', 'reference'],
      },
      minTotal: {
        type: 'number',
      },
      maxTotal: {
        type: 'number',
      },
    },
  },
];

export const exampleCollectionConfig = {
  allowedTypes: ['text', 'image', 'file', 'reference'],
  minTotal: 1,
  maxTotal: 100,
};

export const exampleTextConfig = {
  multiline: false,
  wysiwyg: true,
};

export const exampleReferenceConfig = {
  mode: 'modal',
  allowedTemplates: [
    '00000000-0000-0000-0000-000000000000',
  ],
};
