import de from 'vee-validate/dist/locale/de.json';

export default {
  fields: {
    caption: 'Überschrift',
    contentBlockType: 'Inhaltstyp',
    contentTemplate: 'Inhaltsvorlage',
    email: 'E-Mail',
    file: 'Datei',
    name: 'Name',
    slug: 'Slug',
    status: 'Status',
    text: 'Text',
    password: 'Passwort',
  },
  'fields.translations[de].slug': 'Slug',
  'fields.translations[de].name': 'Name',
  'fields.translations[de].status': 'Status',
  validation: {
    ...de.messages,
    general: 'Dieser Wert ist ungültig',
  },
};
