


























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { MediaObject } from '@/types/resources/MediaObject';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';

@Component({
  components: { ValidationProvider },
})
export default class MediaObjectEditForm extends Vue implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: MediaObject;

  private file: File | null = null;

  get filename() {
    if (this.file instanceof File) {
      return this.file.name;
    }
    if (this.resource?.filename) {
      return this.resource.filename;
    }

    return '';
  }

  get filesize() {
    if (this.file instanceof File) {
      if (this.file.size > 999999) {
        return `${(this.file.size / 1000000).toFixed(2)} MB`;
      }

      return `${Math.ceil(this.file.size / 1000)} KB`;
    }

    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadLocalResource() {}

  prepareLocalResource() {
    if (this.file !== null) {
      const form = new FormData();
      form.append('file', this.file);

      return form;
    }

    return null;
  }

  resetLocalResource() {
    this.file = null;
  }
}
