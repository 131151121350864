/* eslint-disable @typescript-eslint/camelcase */

import validation from './validation/de';

export default {
  ...validation,
  action: {
    viewElement: 'Ansehen',
    editElement: 'Bearbeiten',
    exportRanking: 'Rangliste herunterladen',
    exportGlobalRanking: 'Globale Rangliste herunterladen',
    deleteElement: 'Löschen',
    getEventQrCode: 'Öffne QR Code',
    resetEventRanking: 'Rangliste zurücksetzen',
    resetRankingSure: 'Bist du sicher, dass du die Rangliste des Events "{0}" zurücksetzen möchtest?',
  },
  create: {
    Content: {
      name: 'Name',
      contentBlocks: {
        CollectionContentBlock: {
          contentBlockType: 'Inhaltsblocktyp',
        },
        ReferenceContentBlock: {
          checkDefaultLocale: 'Diese Referenze wird in der Sprache "{0}" gesetzt.',
        },
      },
      slug: 'Slug',
      status: 'Status',
      success: 'Inhalt gespeichert',
      template: {
        select: 'Vorlage auswählen',
      },
      title: 'Inhalt erfassen',
    },
    ImageObject: {
      browse: 'Auswählen',
      caption: 'Überschrift',
      drop: 'Bild hier ablegen',
      file: 'Bilddatei',
      fileSize: 'Dateigröße',
      placeholder: 'Bild auswählen',
      selectedFile: 'Ausgewähltes Bild',
      success: 'ImageObject gespeichert',
      title: 'ImageObject erstellen',
    },
    label: 'Erstellen',
    MediaObject: {
      browse: 'Auswählen',
      drop: 'Datei hier ablegen',
      fileSize: 'Dateigröße',
      label: 'Datei',
      selectedFile: 'Ausgewählte Datei',
      placeholder: 'Datei auswählen',
      success: 'Datei gespeichert',
      title: 'MediaObject erstellen',
    },
    School: {
      title: 'Schule erstellen',
      name: 'Name',
      postalCode: 'Postleitzahl',
    },
    something: '{0} erstellen',
    submitResource: '{0} speichern',
    Template: {
      configuration: 'Template-Konfiguration',
      name: 'Name',
      success: 'Template erstellt',
      segment: {
        configuration: 'Konfiguration',
        contentBlockType: 'Inhaltstyp',
        name: 'Name',
        required: 'Zwingendes Segment',
      },
      status: 'Status',
      title: 'Template erstellen',
    },
    User: {
      email: 'E-Mail',
      password: 'Passwort',
      success: 'Benutzer gespeichert',
      title: 'Benutzer erstellen',
    },
  },
  edit: {
    Content: {
      name: 'Name',
      contentBlocks: {
        ReferenceContentBlock: {
          checkDefaultLocale: 'Diese Referenze wird in der Sprache "{0}" gesetzt.',
        },
      },
      slug: 'Slug',
      status: 'Status',
      success: 'Inhalt gespeichert',
      template: {
        select: 'Vorlage auswählen',
      },
      title: 'Inhalt bearbeiten',
    },
    ImageObject: {
      browse: 'Auswählen',
      caption: 'Überschrift',
      drop: 'Bild hier ablegen',
      file: 'Bilddatei',
      fileSize: 'Dateigröße',
      placeholder: 'Bild auswählen',
      selectedFile: 'Ausgewähltes Bild',
      success: 'ImageObject gespeichert',
      title: 'ImageObject bearbeiten',
    },
    MediaObject: {
      browse: 'Auswählen',
      drop: 'Datei hier ablegen',
      fileSize: 'Dateigröße',
      label: 'Datei',
      selectedFile: 'Ausgewählte Datei',
      placeholder: 'Datei auswählen',
      success: 'Datei gespeichert',
      title: 'MediaObject bearbeiten',
    },
    School: {
      title: 'Schule bearbeiten',
      name: 'Name',
      postalCode: 'Postleitzahl',
    },
    submitResource: '{0} speichern',
    Team: {
      name: 'Teamname',
      captain: 'Captain',
      member: 'Mitglied',
      title: 'Team bearbeiten',
    },
    Template: {
      configuration: 'Template-Konfiguration',
      name: 'Name',
      success: 'Template gespeichert',
      segment: {
        configuration: 'Konfiguration',
        contentBlockType: 'Inhaltstyp',
        name: 'Name',
        required: 'Zwingendes Segment',
      },
      status: 'Status',
      title: 'Template bearbeiten',
    },
    User: {
      email: 'E-Mail',
      password: 'Passwort',
      success: 'Benutzer gespeichert',
      title: 'Bearbeiten von Benutzer {name}',
    },
  },
  error: {
    loadingProperty: 'Fehler beim Laden des Wertes',
    notEditable: 'Diese Resource ist nicht bearbeitbar',
    resourceNotFound: 'Die Resource wurde nicht gefunden',
  },
  filter: {
    label: 'Filter|Filter',
    noFilterSet: 'Du hast keine Filter gesetzt',
    set: 'Gesetzte Filter',
  },
  global: {
    add: 'Hinzufügen',
    addSomething: '{0} hinzufügen',
    back: 'Zurück',
    cancel: 'Abbrechen',
    deletedSomething: '"{0}" wurde gelöscht',
    deletionSomethingUndone: 'Element "{0}" wiederhergestellt',
    edit: 'Bearbeiten',
    errorWhileDeleting: 'Es ist ein Fehler aufgetreten. Bitte versuche es später nochmals',
    loading: 'Lädt',
    noResult: 'Keine Ergebnisse gefunden',
    rankingReset: 'Rangliste für {0} zurückgesetzt',
    saved: 'Gespeichert',
    search: 'Suchen',
    select: 'Auswählen',
    settings: 'Einstellungen',
    submit: 'Absenden',
    typeToSearch: 'Tippe etwas, um zu suchen',
    undo: 'Widerrufen',
    unknownError: 'Ein unbekannter Fehler ist aufgetreten',
    upload: 'Hochladen',
  },
  list: {
    selectProperties: {
      title: 'Anzuzeigende Eigenschaften',
    },
  },
  login: {
    welcome: 'Willkommen beim JKCMS3000!',
  },
  nav: {
    menuLabel: 'Menü',
  },
  resource: {
    Content: {
      label: 'Inhalt',
      status: {
        preview: 'Vorschau',
        published: 'Veröffentlicht',
      },
    },
    ContentBlockCollection: {
      label: 'Blocksammlung',
    },
    EmptyContentBlock: {
      label: 'Neuer leerer Block',
    },
    ImageContentBlock: {
      label: 'Bildblock',
    },
    ImageObject: {
      label: 'ImageObject',
    },
    FileContentBlock: {
      label: 'Dateiblock',
    },
    MediaObject: {
      label: 'MediaObject',
    },
    ReferenceContentBlock: {
      label: 'Referenzblock',
    },
    School: {
      label: 'Schule',
    },
    Team: {
      label: 'Team',
    },
    Template: {
      label: 'Template',
      segment: {
        add: 'Template-Segment hinzufügen',
        label: 'Template-Segment|Template-Segmente',
        labelShort: 'Segment|Segmente',
      },
      status: {
        preview: 'Vorschau',
        published: 'Veröffentlicht',
      },
    },
    TextContentBlock: {
      label: 'Textblock',
    },
    User: {
      label: 'User',
    },
  },
  settings: {
    hierarchy: {
      errorWhenDeletingNode: 'Es ist ein Fehler aufgetreten. Bitte lade die Seite neu.',
      node: {
        children: 'Kinder',
        name: 'Name',
        template: 'Vorlage',
        segment: 'Segment',
        type: {
          label: 'Typ',
          none: 'Keiner',
          segment_on_parent: 'Segment auf Elternteil',
          segment_on_child: 'Segment auf Kind',
        },
      },
      title: 'Seitenhierarchie',
      savedButErrorWhileLoading: 'Die Hierarchie wurde gespeichert, konnte aber nicht neu geladen werden.',
    },
  },
};
