






































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import { Content } from '@/types/resources/Content';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { Translatable } from '@/types/resources/Translatable';
import { TemplateConfiguration } from '@/types/resources/TemplateConfiguration';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { TemplateSegment } from '@/types/resources/Template';
import { ContentStatus } from '@/types/Enums';
import { asyncForEach } from '@/utils/asyncForEach';
import TranslationsMixin from '@/mixins/TranslationsMixin';
import CreateContentBlockMixin from '@/mixins/CreateContentBlockMixin';
import ContentMixin from '@/mixins/ContentMixin';
import ContentBlockConfiguration from '@/components/resources/Content/ContentBlock/ContentBlockConfiguration.vue';

@Component({
  components: { ValidationProvider, ContentBlockConfiguration },
})
export default class ContentEditForm
  extends mixins(TranslationsMixin, ContentMixin, CreateContentBlockMixin)
  implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: Content;

  private localLocaleIndex = 0;

  private loading = true;

  protected content: Partial<Translatable<Content>>
    & { contentBlocks?: Partial<ContentBlockInterface>[] } = {
      translations: {},
      contentBlocks: [],
    };

  get defaultLocale() {
    return process.env.VUE_APP_DEFAULT_LOCALE;
  }

  get statuses(): { value: string; text: string }[] {
    return Object.values(ContentStatus).map((s) => ({
      value: s,
      text: this.$t(`resource.Content.status.${s}`) as string,
    }));
  }

  getCorrespondingSegment(segmentIri: string): Partial<TemplateSegment> | undefined {
    if (typeof this.resource.template === 'object') {
      return this.resource.template?.segments?.find((s) => s['@id'] === segmentIri);
    }

    return undefined;
  }

  async loadLocalResource() {
    // Assign Content to local property
    this.content = this.resource;

    // Add empty translations for all locales (in case they don't exist yet)
    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (this.content.translations && !this.content.translations[locale]) {
        this.content.translations[locale] = {
          name: '',
          slug: '',
          status: ContentStatus.published,
          locale,
        };
      }
    });

    if (typeof this.resource.template === 'object') {
      const { data: template } = await this.$api.get(this.resource.template['@id'] as string);
      this.content.template = template;

      this.content.configuration = template.configurations.map((c: TemplateConfiguration) => {
        const existingConfiguration: null | TemplateConfiguration = this.resource.configuration
          ?.find((e) => e.name === c.name);

        if (existingConfiguration) {
          return {
            name: c.name,
            options: c.multiple ? existingConfiguration.options : existingConfiguration.options[0],
          };
        }
        return {
          name: c.name,
        };
      });

      let { segments } = this.resource.template;
      if (!this.resource.template?.segments && this.resource.template['@id']) {
        const response = await this.$api.get(this.resource.template['@id']);
        if (response.status === 200) {
          segments = response.data.segments;
        }
      }

      if (segments) {
        // Iterate over segments to add missing contentBlocks
        segments.forEach((segment) => {
          if (
            this.content.contentBlocks
            && !this.content.contentBlocks.find((b) => {
              if (typeof b.templateSegment === 'string') {
                return b.templateSegment === segment['@id'];
              }

              return b.templateSegment?.['@id'] === segment['@id'];
            })
          ) {
            this.initializeContentBlockFromSegment(segment);
          }
        });

      //   (Currently contentBlocks get removed when the Segment is removed,
      //   so this part is not necessary. But it might become in the future)
      //   Iterate over contentBlocks to find redundant ones
      //   if (this.content.contentBlocks && segments?.length) {
      //     this.content.contentBlocks.forEach((block) => {
      //       if (segments?.length && segments.find((s) => s.name === block.name)) {
      //         // eslint-disable-next-line no-param-reassign
      //         block.deletable = true;
      //       }
      //     });
      //   }
      }
      // TODO: Order contentBlocks the same way as template
    }

    /*
     * If there are contentBlocks, loop over them and if they have object
     * that we need as IRIs for editing. These include:
     * - templateSegments
     * - references
     * - images
     * - files
     */
    /* eslint-disable no-param-reassign */
    if (this.content.contentBlocks) {
      const { contentBlocks } = this.content;
      await asyncForEach(contentBlocks, async (block) => {
        if (block.type === 'text') {
          await this.loadTextContentBlock(block);
        } else if (block.type === 'reference' && typeof block.reference === 'object') {
          await this.loadReferenceContentBlock(block);
        } else if (block.type === 'image') {
          this.loadImageContentBlock(block);
        } else if (block.type === 'file') {
          this.loadFileContentBlock(block);
        } else if (block.type === 'collection') {
          await this.loadCollectionContentBlock(block);
        }
      });
      /* eslint-enable no-param-reassign */

      // Sort the contentBlocks
      this.content.contentBlocks.sort((a, b) => {
        if (a.position < b.position) { return -1; }
        if (a.position > b.position) { return 1; }
        return 0;
      });

      // Pull Media ContentBlock to first place
      const mediaIdx = this.content.contentBlocks.findIndex((b) => b.name === 'Media');
      if (mediaIdx > -1) {
        this.content.contentBlocks.splice(1, 0, this.content.contentBlocks.splice(mediaIdx, 1)[0]);
      }
    }
    this.loading = false;
  }

  // prepareLocalResource() in ContentMixin

  resetLocalResource(): void {
    this.loadLocalResource();
  }
}
