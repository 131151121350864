


















import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import { HierarchyNode } from '@/types/resources/HierarchyNode';

@Component
export default class HierarchyNodes extends Vue {
  @Prop({ type: Array, default: null }) index!: number[] | null;

  @PropSync('hierarchy', { type: Array, default: () => [] }) hierarchyNodes!: Partial<HierarchyNode>[];

  addNode(index: number[]) {
    this.$emit('add-node', index);
  }

  removeNode(index: number[]) {
    this.$emit('remove-node', index);
  }
}
