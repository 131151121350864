import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from './de';
import fr from './fr';
import it from './it';

Vue.use(VueI18n);

const messages = {
  de,
  it,
  fr,
};

const i18n = new VueI18n({
  locale: 'de',
  messages,
});

export default i18n;
