



































import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { ApiResource } from '@/types/resources/ApiResource';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';

@Component({
  components: { ValidationProvider },
})
export default class ImageObjectCreateForm extends Vue implements CreateFormInterface {
  $refs!: {
    imagePreview: HTMLImageElement;
  }

  private file: File | null = null;

  private caption = '';

  get filename() {
    if (this.file instanceof File) {
      return this.file.name;
    }

    return '';
  }

  get filesize() {
    if (this.file instanceof File) {
      if (this.file.size > 999999) {
        return `${(this.file.size / 1000000).toFixed(2)} MB`;
      }

      return `${Math.ceil(this.file.size / 1000)} KB`;
    }

    return '';
  }

  prepareLocalResource(): Partial<ApiResource> | FormData | null {
    const form = new FormData();
    form.append('caption', this.caption);
    if (this.file !== null) {
      form.append('file', this.file);
    }

    return form;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetLocalResource(): void {
    this.file = null;
    this.caption = '';
  }

  @Watch('file')
  updatePreview(newValue: File) {
    this.$refs.imagePreview.src = URL.createObjectURL(newValue);
    this.$refs.imagePreview.onload = () => {
      URL.revokeObjectURL(this.$refs.imagePreview.src); // free memory
    };
  }
}
