import Vue from 'vue';
import {
  AlertPlugin,
  AvatarPlugin,
  BadgePlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  LayoutPlugin,
  NavbarPlugin,
  ModalPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  SidebarPlugin,
  TabsPlugin,
  TablePlugin,
  ToastPlugin,
  TooltipPlugin,
} from 'bootstrap-vue';

Vue.use(AlertPlugin);
Vue.use(AvatarPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);
Vue.use(ModalPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(SidebarPlugin);
Vue.use(TabsPlugin);
Vue.use(TablePlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
