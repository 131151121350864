























































import Draggable from 'vuedraggable';
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { Template } from '@/types/resources/Template';
import { mixins } from 'vue-class-component';
import TemplateSegmentForm from '@/components/resources/Template/TemplateSegmentForm.vue';
import TemplateMixin from '@/mixins/Template/TemplateMixin';
import { TemplateConfiguration } from '@/types/resources/TemplateConfiguration';

@Component({
  components: { Draggable, TemplateSegmentForm, ValidationProvider },
})
export default class TemplateEditForm extends mixins(TemplateMixin) implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: Template;

  private templateConfigurationValid: boolean | null = null;

  loadLocalResource(): void {
    this.template = this.resource;
    if (this.template.configurations) {
      this.template.configurations = this.template.configurations.map(
        (c: TemplateConfiguration) => ({
          ...c,
          id: c['@id'],
          '@id': undefined,
          '@type': undefined,
          createdAt: undefined,
          updatedAt: undefined,
        }),
      );
    }

    if (this.template.segments) {
      this.template.segments = this.template.segments.map((s) => ({
        ...s,
        configurations: s.configurations ? s.configurations.map((c: TemplateConfiguration) => ({
          ...c,
          id: c['@id'],
          '@id': undefined,
          '@type': undefined,
          createdAt: undefined,
          updatedAt: undefined,
        })) : [],
      }));
    }
  }

  resetLocalResource(): void {
    this.loadLocalResource();
  }

  get templateConfiguration(): string {
    if (this.template?.configurations) {
      return JSON.stringify(this.template.configurations, undefined, 4);
    }

    return '[]';
  }

  set templateConfiguration(value: string) {
    if (this.template?.configurations) {
      try {
        this.template.configurations = JSON.parse(value);
        this.templateConfigurationValid = true;
      } catch (e) {
        this.templateConfigurationValid = false;
      }
    }
  }
}
