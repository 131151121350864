





























import { Vue, Component } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { User } from '@/types/resources/User';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';

@Component({
  components: { ValidationProvider },
})
export default class UserCreateForm extends Vue implements CreateFormInterface {
  private user: Partial<User> = {};

  resetLocalResource() {
    this.user.email = '';
    this.user.password = '';
  }

  prepareLocalResource(): Partial<User> {
    return this.user;
  }
}
