







































































































































import { Component, Prop, PropSync } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Treeselect, ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
import { ReferenceContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import TranslationsMixin from '@/mixins/TranslationsMixin';
import ContentCreateForm from '@/components/resources/Content/ContentCreateForm.vue';
import ContentEditForm from '@/components/resources/Content/ContentEditForm.vue';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { Content } from '@/types/resources/Content';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';
import _Vue from 'vue';
import { Violation, ViolationMapping } from '@/types/shared/Violations';
import { ValidationObserver } from 'vee-validate';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { TemplateSegment } from '@/types/resources/Template';

@Component({
  components: {
    ContentEditForm,
    ContentCreateForm,
    ContentBlockFormWrapper,
    Treeselect,
    ValidationObserver,
  },
})
export default class ReferenceContentBlockForm
  extends mixins(TranslationsMixin) implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @Prop({ type: Object, default: undefined }) segment!: Partial<TemplateSegment> | undefined;

  @PropSync('contentBlock', { type: Object, required: true })
  syncedContentBlock!: ReferenceContentBlockInterface;

  private contentToEdit: Content | null = null;

  private saving = false;

  private loadingEdit = false;

  private backendErrors: string[] = [];

  get id() {
    const { name } = this.syncedContentBlock;
    let segmentId = '';
    if (typeof this.syncedContentBlock.templateSegment === 'string') {
      segmentId = `${this.syncedContentBlock.templateSegment}`;
    } else if (this.syncedContentBlock.templateSegment?.id) {
      segmentId = this.syncedContentBlock.templateSegment.id;
    } else if (this.syncedContentBlock.templateSegment?.['@id']) {
      segmentId = this.syncedContentBlock.templateSegment['@id'];
    } else {
      // For ReferenceContentBlocks in CollectionContentBlocks that have no segments
      segmentId = Math.random().toString(20).slice(2, 10);
    }

    return `${name.replace(' ', '-')}_${segmentId}`;
  }

  get defaultLocale() {
    return process.env.VUE_APP_DEFAULT_LOCALE;
  }

  /**
   * Only render the modal to edit contents when mode is set to modal
   * and we are rendering the default locale.
   */
  get renderModal(): boolean {
    if (this.locale !== this.defaultLocale) {
      return false;
    }

    if (this.syncedContentBlock.config?.mode) {
      return this.syncedContentBlock.config?.mode === 'modal';
    }

    return false;
  }

  showModal(): void {
    this.$bvModal.show(`${this.id}_modal`);
    this.$nextTick(() => {
      (this.$refs[`${this.id}_createComponent`] as _Vue & CreateFormInterface).resetLocalResource();
    });
  }

  async showEditModal(): Promise<void> {
    this.loadingEdit = true;
    try {
      let referenceIri = '';
      if (typeof this.syncedContentBlock.reference === 'string') {
        referenceIri = this.syncedContentBlock.reference;
      } else {
        referenceIri = this.syncedContentBlock.reference['@id'];
      }
      const response = await this.$api.get(referenceIri, {
        params: {
          groups: ['translations'],
          locale: this.$root.$i18n.locale,
        },
      });
      if (response?.status === 200 && response.data) {
        this.contentToEdit = response.data;
        this.$bvModal.show(`${this.id}_editModal`);
        this.$nextTick(() => {
          (this.$refs[`${this.id}_editComponent`] as _Vue & EditFormInterface).loadLocalResource();
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e, e.response, e.response?.data);
    }
    this.loadingEdit = false;
  }

  async handleContentSubmit(bvModalEvt: Event): Promise<void> {
    bvModalEvt.preventDefault();

    const formRef = this.$refs[`${this.id}_contentForm`] as _Vue & InstanceType<typeof ValidationObserver>;

    this.saving = true;
    // Access prepareLocalResource from Children
    const resourceToPost = await (this.$refs[`${this.id}_createComponent`] as _Vue & CreateFormInterface).prepareLocalResource();

    if (resourceToPost === null) {
      formRef.setErrors({ name: this.$t('critical_error') as string });
      return;
    }

    try {
      const response = await this.$api.post('/api/contents', resourceToPost);

      if (response.status === 201) {
        this.saving = false;
        formRef.reset();
        this.backendErrors = [];
        this.syncedContentBlock.reference = response.data['@id'];
        // These cascaded $nextTicks are because something is not reactive
        // and because Treeselect needs time to create it's Fallback node
        // before we can set the correct label.
        this.$nextTick(() => {
          this.$forceUpdate();
          this.$bvModal.hide(`${this.id}_modal`);
          this.$nextTick(() => {
            // This line loads the name in Treeselect's cache so it has a label
            // eslint-disable-next-line
            (this.$refs[this.id] as any).forest.nodeMap[response.data['@id']] = {
              label: response.data.translations[this.defaultLocale].name,
            };
          });
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      if (e.response?.data['@type'] === 'ConstraintViolationList') {
        const violations: ViolationMapping = {};
        e.response.data.violations.forEach((v: Violation) => {
          violations[v.propertyPath] = [v.message];
        });
        formRef.setErrors(violations);
        this.backendErrors = e.response.data['hydra:description'].split('\n');
      }
      this.saving = false;
    }
  }

  async handleContentEditSubmit(): Promise<void> {
    const formRef = this.$refs[`${this.id}_contentEditForm`] as _Vue & InstanceType<typeof ValidationObserver>;

    this.saving = true;

    // Access prepareLocalResource from Children
    const resourceToPut = await (this.$refs[`${this.id}_editComponent`] as _Vue & EditFormInterface).prepareLocalResource();

    if (resourceToPut === null) {
      formRef.setErrors({ name: this.$t('critical_error') as string });
      return;
    }

    try {
      const response = await this.$api.put((this.contentToEdit as Content)['@id'], resourceToPut);

      if (response?.status === 200) {
        this.saving = false;
        formRef.reset();
        this.backendErrors = [];
        this.syncedContentBlock.reference = response.data['@id'];
        // These cascaded $nextTicks are because something is not reactive
        // and because Treeselect needs time to create it's Fallback node
        // before we can set the correct label.
        this.$nextTick(() => {
          this.$forceUpdate();
          this.$bvModal.hide(`${this.id}_editModal`);
          this.$nextTick(() => {
            // This line loads the name in Treeselect's cache so it has a label
            // eslint-disable-next-line
            (this.$refs[this.id] as any).forest.nodeMap[response.data['@id']] = {
              label: response.data.translations[this.defaultLocale].name,
            };
          });
        });
      }
    } catch (e) {
      if (e.response?.data['@type'] === 'ConstraintViolationList') {
        const violations: ViolationMapping = {};
        e.response.data.violations.forEach((v: Violation) => {
          violations[v.propertyPath] = [v.message];
        });
        formRef.setErrors(violations);
        this.backendErrors = e.response.data['hydra:description'].split('\n');
      }
      this.saving = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadContents({ action, searchQuery, callback }: any) {
    if (action === ASYNC_SEARCH) {
      const templates = this.syncedContentBlock.config?.allowedTemplates;
      const response = await this.$api.get('/api/contents', {
        params: {
          locale: this.$root.$i18n.locale,
          'translations.name': searchQuery,
          template: Array.isArray(templates) && templates.length
            ? this.syncedContentBlock.config?.allowedTemplates : undefined,
          properties: ['name'],
        },
      });

      if (response.status === 200 && response.data['hydra:member']) {
        callback(null, response.data['hydra:member'].map((content: Content) => ({
          id: content['@id'],
          label: content.name,
        })));
      }
    }
  }

  async mounted() {
    if (
      typeof this.syncedContentBlock.reference === 'string'
      && this.$refs[this.id]
    ) {
      // Only get name, as it is only used to fill Treeselect's cache to show the label
      const response = await this.$api.get(this.syncedContentBlock.reference, {
        params: {
          properties: ['name'],
        },
      });
      if (response.status === 200) {
        // eslint-disable-next-line
        (this.$refs[this.id] as any)
          .forest.nodeMap[this.syncedContentBlock.reference].label = response.data.name;
      }
    }
  }
}
