var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading && _vm.content.template && _vm.content.template.configurations.length)?[_vm._l((_vm.content.template.configurations),function(configuration,index){return [_c('b-form-group',{key:'contentConfiguration-' + index,attrs:{"label-for":'contentConfiguration-' + index,"label":configuration.name}},[(configuration.multiple)?_c('b-form-checkbox-group',{attrs:{"id":'contentConfiguration-' + index,"options":configuration.options,"name":configuration.name},model:{value:(_vm.content.configuration[index].options),callback:function ($$v) {_vm.$set(_vm.content.configuration[index], "options", $$v)},expression:"content.configuration[index].options"}}):_c('b-form-radio-group',{attrs:{"id":'contentConfiguration-' + index,"options":configuration.options,"name":configuration.name},model:{value:(_vm.content.configuration[index].options),callback:function ($$v) {_vm.$set(_vm.content.configuration[index], "options", $$v)},expression:"content.configuration[index].options"}})],1)]}),_c('hr')]:_vm._e(),_c('b-tabs',_vm._l((_vm.content.translations),function(localeValue,locale){return _c('b-tab',{key:locale,staticClass:"pt-3",attrs:{"active":locale === _vm.$root.$i18n.locale,"title":locale.toUpperCase()}},[_c('b-form-group',{attrs:{"label-for":'translations[' + locale + '].name',"label":_vm.$t('create.Content.name')}},[_c('validation-provider',{attrs:{"rules":locale === _vm.defaultLocale ? 'required' : '',"name":'translations[' + locale + '].name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":'translations[' + locale + '].name',"state":errors[0] ? false : (valid ? true : null),"required":locale === _vm.defaultLocale},model:{value:(_vm.content.translations[locale].name),callback:function ($$v) {_vm.$set(_vm.content.translations[locale], "name", $$v)},expression:"content.translations[locale].name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":'translations[' + locale + '].status',"label":_vm.$t('create.Content.status')}},[_c('validation-provider',{attrs:{"rules":locale === _vm.defaultLocale ? 'required' : '',"name":'translations[' + locale + '].status'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-select',{attrs:{"id":'translations[' + locale + '].status',"options":_vm.statuses,"state":errors[0] ? false : (valid ? true : null),"required":locale === _vm.defaultLocale},model:{value:(_vm.content.translations[locale].status),callback:function ($$v) {_vm.$set(_vm.content.translations[locale], "status", $$v)},expression:"content.translations[locale].status"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.loading)?_c('div',{key:_vm.content.template['@id'] ? _vm.content.template['@id'] : _vm.content.template},_vm._l((_vm.content.contentBlocks),function(block){return _c(_vm.getContentBlockComponentFromType(block.type),{key:typeof block.templateSegment === 'string'
          ? block.templateSegment : block.templateSegment['@id'],tag:"component",attrs:{"content-block":block,"locale":locale,"selected-locale":_vm.$root.$i18n.availableLocales[_vm.localLocaleIndex],"segment":_vm.getCorrespondingSegment(typeof block.templateSegment === 'string'
          ? block.templateSegment : block.templateSegment['@id'])},on:{"update:contentBlock":function($event){block=$event},"update:content-block":function($event){block=$event}},scopedSlots:_vm._u([{key:"configuration",fn:function(){return [(locale === _vm.defaultLocale)?_c('content-block-configuration',{attrs:{"segment":_vm.getCorrespondingSegment(block.templateSegment),"locale":locale},model:{value:(block.configuration),callback:function ($$v) {_vm.$set(block, "configuration", $$v)},expression:"block.configuration"}}):_vm._e()]},proxy:true}],null,true)})}),1):_vm._e()],1)}),1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }