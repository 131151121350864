/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { ImageContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { mixins } from 'vue-class-component';
import TemplateSegmentConfigurationMixin
  from '@/mixins/ContentBlocks/shared/TemplateSegmentConfigurationMixin';

@Component
export default class ImageContentBlockMixin extends mixins(TemplateSegmentConfigurationMixin) {
  loadImageContentBlock(block: Translatable<ImageContentBlockInterface>) {
    Object.keys(block.translations).forEach((locale: string) => {
      const { image } = block.translations[locale];
      if (image && typeof image === 'object') {
        block.translations[locale].image = image['@id'];
      }
    });

    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (block.translations[locale] === undefined) {
        block.translations[locale] = {
          id: undefined,
          reference: undefined,
          name: block.name,
          locale,
        };
      }
    });
  }

  prepareImageContentBlock(block: Translatable<ImageContentBlockInterface>):
    Partial<Translatable<ImageContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<ImageContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      type: 'image',
    };

    preparedBlock.configuration = this.prepareContentBlockConfiguration(block);

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        if (block.translations[locale].image) {
          preparedBlock.translations[locale] = {
            id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
            templateSegment,
            image: block.translations[locale].image,
            name: block.translations[locale].name,
            locale,
          };
        }
      }
    });

    // eslint-disable-next-line
    // @ts-ignore
    if (Object.keys(preparedBlock.translations).length === 0) {
      return undefined;
    }

    return preparedBlock;
  }
}
