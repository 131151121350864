import { Component, Vue } from 'vue-property-decorator';
import { TemplateSegmentConfiguration } from '@/types/resources/TemplateConfiguration';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';

@Component
export default class TemplateSegmentConfigurationMixin extends Vue {
  prepareContentBlockConfiguration(block: Partial<ContentBlockInterface>):
    Partial<TemplateSegmentConfiguration>[] {
    if (block.configuration?.length) {
      return block.configuration
        .filter((c: TemplateSegmentConfiguration) => c != null)
        .map((c: Partial<TemplateSegmentConfiguration>) => {
          let { options } = c;
          if (c.options === undefined) {
            options = [];
          } else if (!Array.isArray(c.options)) {
            options = [c.options];
          }

          return { name: c.name, options };
        });
    }

    return [];
  }
}
